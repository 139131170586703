import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ingridientService from "./ingridientService";



const initialState = {
    ingridients: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}


// @****************************************@
// ---------------GET ALL Courses ----
// @****************************************@

export const getAllIngridients = createAsyncThunk('courses/getAllIngridients', async (_, thunkAPI) => {
    try {
        return await ingridientService.getAllIngridients()
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

// @****************************************@
// ---------------GET Single Course ----
// @****************************************@

export const getIngridient = createAsyncThunk('ingridients/getCourse', async (id, thunkAPI) => {
    try {
        return await ingridientService.getIngridient(id)
    } catch (
    error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)

    }
})



export const ingridientSlice = createSlice({
    name: 'ingridients',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllIngridients.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getAllIngridients.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.ingridients = action.payload
            })
            .addCase(getAllIngridients.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.ingridients = []
            })
            .addCase(getIngridient.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getIngridient.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.ingridients = action.payload
            })
            .addCase(getIngridient.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.ingridients = []
            })

    }
})



export const { reset } = ingridientSlice.actions
export default ingridientSlice.reducer;
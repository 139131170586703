import React from 'react'
import { Link } from 'react-router-dom'
import './Data.css'
function DataCourse({ course }) {
    return (
        <Link className='Link' to={`/course/${course._id}`}>
            <div className="job-card overview-card">
                <div className="overview-wrapper">
                    <img src={course.images[0].url} alt="" />
                    <div className="overview-detail">
                        <div className="job-card-title">{course.title}</div>
                        <div className="job-card-subtitle">
                            {course.categories[0]}
                        </div>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="heart feather feather-heart">
                        <path d="M20.8 4.6a5.5 5.5 0 00-7.7 0l-1.1 1-1-1a5.5 5.5 0 00-7.8 7.8l1 1 7.8 7.8 7.8-7.7 1-1.1a5.5 5.5 0 000-7.8z" /></svg>
                </div>
                <div className="job-overview-buttons">
                    <div className="search-buttons time-button">{course.duration}</div>
                    {/* <div className="search-buttons level-button"></div> */}
                    <div className="job-stat">Ksh {course.cost}</div>
                    <div className="job-day">Call: 0708446730</div>
                </div>
            </div>
        </Link>
    )
}

export default DataCourse
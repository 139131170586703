// authService.js
import axios from 'axios';

const API_URL = 'https://abanchiqschoolapi.onrender.com/api/auth/';
// const API_URL_VERIFY = 'https://abanchiqschoolapi.onrender.com/api/auth/verify/';
// const API_URL_RESET = 'https://abanchiqschoolapi.onrender.com/api/auth/config/resetbymail';
// const API_URL_RESET_PASSWORD = 'https://abanchiqschoolapi.onrender.com/api/auth/config/resetpassword';


// Login user
const login = async (formData) => {
    const response = await axios.post(API_URL + 'login', formData);
    return response.data;
};



const authService = {
    login,

};

export default authService;

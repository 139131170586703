import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "./authService";


// Get user from local storage

// const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
    currentStep: 1,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
};



// Async thunk for logging in a user
export const pay = createAsyncThunk('payment/pay', async (formData, thunkAPI) => {
    try {
        return await authService.login(formData);
    } catch (error) {
        const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});


export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = false;
            state.message = '';
        },
        setCurrentStep: (state, action) => {
            state.currentStep = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(pay.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(pay.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                // state.user = action.payload;
            })
            .addCase(pay.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                // state.user = null;
            })
    },
});

export const { reset, setCurrentStep } = paymentSlice.actions;
export default paymentSlice.reducer;

import React from 'react'
import './lecs.css'
function Lecturers() {
    return (
        <div className='Lecturers'>
            <h3>Our Amazing Lecs</h3>
            <div className="card-wrapper">
                <div className="card">
                    <img src="https://images.unsplash.com/photo-1628375385899-2221336eecb0?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGJsYWNrbWFufGVufDB8fDB8fHww" alt="user profile" />
                    <h1>
                        Alex Oduor
                    </h1>
                    <p>@Alekii</p>

                    <input type="button" value="Formulator" />
                </div>

                <div className="card">
                    <img src="https://images.unsplash.com/photo-1611432579402-7037e3e2c1e4?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8YmxhY2slMjB3b21hbnxlbnwwfHwwfHx8MA%3D%3D" alt="user profile" />

                    <h1>
                        Serah Makui
                    </h1>
                    <p>@MadamSerah</p>

                    <input type="button" value="Formulator" />
                </div>

                <div className="card">
                    <img src="https://images.unsplash.com/photo-1579880651719-3cef00eae7de?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8YmxhY2slMjBtYW58ZW58MHx8MHx8fDA%3D" alt="user profile" />

                    <h1>
                        Joel Maina
                    </h1>
                    <p>@joel</p>

                    <input type="button" value="Formulator" />
                </div>
            </div>
        </div>
    )
}

export default Lecturers
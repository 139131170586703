import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import courseService from "./courseService";

const initialState = {
    courses: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

// Async thunks
export const getAllCourses = createAsyncThunk('courses/getAllCourses', async (_, thunkAPI) => {
    try {
        return await courseService.getAllCourses();
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getAllNewCourses = createAsyncThunk('courses/getAllNewCourses', async (_, thunkAPI) => {
    try {
        return await courseService.getNewCourses();
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});
export const getAllCategoryCourses = createAsyncThunk('courses/getAllCategoryCourses', async (category, thunkAPI) => {
    try {
        return await courseService.getCoursesByCategory(category);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

export const getCourse = createAsyncThunk('courses/getCourse', async (id, thunkAPI) => {
    try {
        return await courseService.getCourse(id);
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        return thunkAPI.rejectWithValue(message);
    }
});

// Slice
export const courseSlice = createSlice({
    name: 'courses',
    initialState,
    reducers: {
        reset: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllCourses.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllCourses.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.courses = action.payload;
            })
            .addCase(getAllCourses.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.courses = [];
            })
            .addCase(getAllNewCourses.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllNewCourses.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                // Assuming you want to append new courses to the existing list
                state.courses = action.payload;
            })
            .addCase(getAllNewCourses.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                // Not clearing existing courses on error to maintain current UI state
            })
            .addCase(getAllCategoryCourses.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllCategoryCourses.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                // Assuming you want to append new courses to the existing list
                state.courses = action.payload;
            })
            .addCase(getAllCategoryCourses.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                // Not clearing existing courses on error to maintain current UI state
            })
            .addCase(getCourse.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getCourse.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                // Handle single course payload, potentially adding or updating an entry in the courses array
            })
            .addCase(getCourse.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                // Not clearing existing courses on error to maintain current UI state
            });
    },
});

export const { reset } = courseSlice.actions;
export default courseSlice.reducer;

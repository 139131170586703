import React from 'react'
import ApplicationApp from '../../Components/Application/Application'


function Application() {
    return (
        <div className='Application'>
            <ApplicationApp />
        </div>
    )
}

export default Application
import React from 'react'


function Highlights({ feed }) {

    return (
        <div className="course-roll">
            <div className="pic">
                <img src={feed.images[0].url} alt="profile" />
            </div>
            <div className="data">
                <h3>{feed.title}</h3>
                <h4>{feed.description}</h4>
            </div>
        </div>
    )
}

export default Highlights
import React, { useEffect } from 'react'
import './app.css'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from "react-toastify"
import { CiBellOn } from "react-icons/ci";
import { TiArrowBack } from "react-icons/ti";
import PostCard from './PostCard.js'
import AdminFeeds from './AdminFeeds.js'
import { getAllLecFeeds } from '../../features/Lecturers/lecSlice.js'
import Spinner from '../Spinner/Spinner.js'



function Application() {
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.auth)

    const { Feeds, isLoading, message } = useSelector((state) => state.school)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!user) {
            toast.warning(message)
            navigate('/login')
        }
    }, [user, navigate, message])

    useEffect(() => {
        dispatch(getAllLecFeeds())
    }, [dispatch])



    return (
        <div className='Application-All'>
            <Link className="return" to='/'>
                <TiArrowBack />
            </Link>
            <div className="cover">
                <div className="left">
                    <div className="wrapper2">
                        <div className="coverL">
                            <div className="pic">
                                <img src={user.profilePicture.url} alt='profile' />
                            </div>
                            <div className="pdata">
                                <h4>Student</h4>
                                <h3>@{user.name}</h3>
                            </div>
                        </div>
                        <div className="course-data">
                            <div className="my-course">
                                <h4>Enrolled</h4>
                                <h4 className="show">3</h4>
                            </div>
                            <div className="my-course">
                                <h4>Ongoing</h4>
                                <h4 className="show">2</h4>
                            </div>
                            <div className="my-course">
                                <h4>Completed</h4>
                                <h4 className="show">1</h4>
                            </div>
                        </div>
                    </div>
                    <AdminFeeds />
                </div>
                <div className="center">
                    <div className="search-bar">
                        <input type="text" placeholder="search course news ..." />
                    </div>
                    <div className="blogs">
                        {isLoading && <Spinner />}
                        {
                            Feeds.map((feed) => (
                                <PostCard key={feed._id} feed={feed} />
                            ))
                        }
                    </div>
                </div>



                <div className="right">
                    <h3>My Notifications</h3>
                    <div className="container">
                        <div className="rectangle">
                            <div className="notification-text">
                                <CiBellOn className="material-icons" />
                                <span>This is a test notification.</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Application
import React from 'react'
import './Faq.css'
import Header from '../../Components/Header/Header'
import { CiHome } from "react-icons/ci";
import { Link } from 'react-router-dom';



function Faq() {
    return (
        <div className='Faq'>

            <Header />
            <div id="faq" className="faq-body">
                <div className="faq-header">
                    <h3 className="faq-title">FAQ's</h3>
                    <div className="seperator"></div>
                </div>
                <div className="faq-list">
                    <div>
                        <details open>
                            <summary title="How can I pay for my appointment?">What is the ABANCHIQ School of Formulation?</summary>
                            <p className="faq-content">ABANCHIQ School of Formulation is an educational platform dedicated to teaching individuals how to formulate beauty products, package, market, and sell them. We also assist in generating or creating unique formulas.</p>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary title="What can I expect at my first consultation?">Who can enroll in the ABANCHIQ School of Formulation?</summary>
                            <p className="faq-content">Anyone interested in learning how to create, market, and sell beauty products is welcome to enroll. This includes budding entrepreneurs, cosmetic enthusiasts, and professionals looking to expand their skillset.</p>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary title="What are your opening hours?">What types of programs are offered at ABANCHIQ School of Formulation?</summary>
                            <p className="faq-content">We offer a range of programs covering beauty product formulation, packaging design, marketing strategies, and sales channels. Each program is designed to provide comprehensive knowledge and hands-on experience.</p>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary title="Do I need a referral?">Do I need prior experience in cosmetic formulation to enroll?</summary>
                            <p className="faq-content">No, prior experience is not required. Our courses cater to all levels, from beginners to those with some experience looking to refine their skills.</p>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary title="Is the cost of the appointment covered by private health insurance?">Can I learn how to create formulas for specific types of beauty products?</summary>
                            <p className="faq-content">Yes, our courses cover various product types, including skincare, haircare, makeup, and more. You'll learn to create formulas tailored to different beauty segments.</p>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary title="What are the parking and public transport options?">How do the courses teach product packaging?</summary>
                            <p className="faq-content">Our packaging courses focus on both aesthetic design and functionality. You'll learn about choosing the right materials, sustainability practices, and branding strategies.</p>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary title="What are the parking and public transport options?">What marketing strategies will I learn at ABANCHIQ School of Formulation?</summary>
                            <p className="faq-content">You'll be introduced to digital marketing, brand building, social media strategies, and other essential techniques to successfully market your beauty products.</p>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary title="What are the parking and public transport options?">Will I learn where and how to sell my products?</summary>
                            <p className="faq-content">Absolutely. We provide guidance on various sales channels, including online platforms, retail partnerships, and direct sales strategies to help you find the best outlets for your products.</p>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary title="What are the parking and public transport options?">Do you offer online courses or are all programs held in-person?</summary>
                            <p className="faq-content">We offer both online and in-person courses to accommodate students from different locations and with varying schedules.</p>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary title="What are the parking and public transport options?">How long are the courses at ABANCHIQ School of Formulation?</summary>
                            <p className="faq-content">Course lengths vary depending on the subject and depth of material covered. They can range from short workshops to more extensive programs lasting several weeks or months.</p>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary title="What are the parking and public transport options?">Is there hands-on experience included in the courses?</summary>
                            <p className="faq-content">Yes, practical experience is a core part of our curriculum. Students will have the opportunity to work on real-life projects to apply what they've learned.</p>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary title="What are the parking and public transport options?">Can I get assistance in generating my own beauty product formula?</summary>
                            <p className="faq-content">Yes, we offer personalized assistance in creating or refining your beauty product formulas through our specialized courses and mentorship programs.</p>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary title="What are the parking and public transport options?">Are there any resources for students after completing their courses?</summary>
                            <p className="faq-content">Graduates have access to our alumni network, ongoing mentorship, and resources to help them continue growing their businesses and skills.</p>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary title="What are the parking and public transport options?">What is the average className size for courses at ABANCHIQ School of Formulation?</summary>
                            <p className="faq-content">We keep our className sizes small to ensure personalized attention and a quality learning experience for all students.</p>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary title="What are the parking and public transport options?">Will I receive a certification upon completing a course?</summary>
                            <p className="faq-content">Yes, students who successfully complete their courses will receive a certification from ABANCHIQ School of Formulation.</p>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary title="What are the parking and public transport options?">How do I enroll in a course at ABANCHIQ School of Formulation?</summary>
                            <p className="faq-content">You can enroll through our website by selecting the course you're interested in and following the registration process</p>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary title="What are the parking and public transport options?">Are there any scholarships or financial aid options available?</summary>
                            <p className="faq-content">We offer a limited number of scholarships and financial aid options for qualifying students. Please check our website or contact us for more information.</p>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary title="What are the parking and public transport options?">Can I visit the school before enrolling?</summary>
                            <p className="faq-content">Yes, we encourage potential students to visit us. Please contact us to schedule a tour and meet some of our instructors.</p>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary title="What are the parking and public transport options?">How does ABANCHIQ School of Formulation stay updated with industry trends?</summary>
                            <p className="faq-content">Our instructors are industry professionals who continuously engage with the beauty sector, ensuring our curriculum remains current with the latest trends and technologies.</p>
                        </details>
                    </div>
                    <div>
                        <details>
                            <summary title="What are the parking and public transport options?">Who can I contact if I have more questions?</summary>
                            <p className="faq-content">For more inquiries, please reach out to our admissions team via email or phone provided on our website. We're here to help with any questions you may have.We actually respond very fast...</p>
                        </details>
                    </div>
                </div>
                <Link className="Link dataHomeLink" to='/'><CiHome /></Link>
            </div>
        </div>
    )
}

export default Faq
import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/Auth/authSlice';
import courseReducer from '../features/Courses/courseSlice';
import courseIdReducer from '../features/Courses/courseIdSlice';
import adminReducer from '../features/Admin/adminSlice';
import schoolReducer from '../features/Lecturers/lecSlice';
import newsReducer from '../features/News/newsSlice';
import ingridientReducer from '../features/Ingridents/ingridientSlice';
import appointmentReducer from '../features/Appointment/appointmentSlice';
import paymentReducer from '../features/Payment/paymentSlice';


export const store = configureStore({
  reducer: {
    auth: authReducer,
    courses: courseReducer,
    admin: adminReducer,
    school: schoolReducer,
    news: newsReducer,
    Id: courseIdReducer,
    Shop: ingridientReducer,
    Appointment: appointmentReducer,
    payment: paymentReducer,
  },
});

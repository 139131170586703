import React, { useEffect } from 'react'
import './HomePage.css'
import Header from '../Header/Header.js'
import Lecturers from "../Lecturers/Lecturers.js"
import Testimonials from './../Testimonials/Testimonials.js'
import Footer from './../Footer/Footer.js'
import NewCourses from './NewCoursesCard/NewCoursesCard.js'
import CoursesCard from './CoursesCard/CoursesCard.js'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../Spinner/Spinner.js'
import News from '../Banner/News.js'
import { getAllNewCourses, reset } from '../../features/Courses/courseSlice.js'
import { Helmet } from 'react-helmet-async'


function HomePage() {

    const { courses, isLoading } = useSelector((state) => state.courses)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllNewCourses())
        dispatch(reset())
    }, [dispatch])



    return (
        <>
            <Helmet>
                <title>Abanchiq School Of Formulatation</title>
                <meta name="description" content="Welcome to the Abanchiq School of Formulation, where the art and science of beauty product creation come to life! Nestled within our virtual corridors, we offer a transformative journey for aspiring entrepreneurs and beauty enthusiasts alike.At Abanchiq, we empower you to master the intricate craft of formulating an array of beauty products, from luxurious skincare concoctions to vibrant cosmetics and fragrances. Our expert mentors guide you through every step of the process, from conceptualization to production, ensuring your creations meet the highest standards of quality and efficacy.But we don't stop there. Beyond formulation, we provide comprehensive training in branding and packaging, equipping you with the tools to craft captivating identities for your products that resonate with your target audience. Our cutting-edge curriculum also delves into marketing strategies and sales techniques, arming you with the knowledge to successfully launch and promote your brand in today's competitive beauty landscape." />
                <meta name="keywords" content="Abanchiq School of Formulation, Abanchiq, School of Formulation, School of Beauty, School of Cosmetics, School of Fragrances, School of Skincare, School of Luxury, School of Beauty Products, School of Cosmetics Products, School of Fragrances" />
                <meta name="author" content="Abanchiq" />
                {/* <meta name="robots" content="index, follow" /> */}
                <link rel="canonical" href="https://www.abanchiqschoolofformulation.com/" />
            </Helmet>

            <div className='HomePage'>
                <div className="job">
                    <Header />
                    <div className="wrapper">
                        <div className="searched-title">
                            <News />
                        </div>
                        <div className="main-container">
                            <div className="search-type">
                                <div className="cardFeature">
                                    < NewCourses />
                                </div>
                            </div>
                            <div className="searched-jobs">
                                <div className="searched-bar">
                                    <div className="searched-show">Abanchiq New Courses</div>
                                    <div className="searched-sort">Sort by: <span className="post-time">Newest Course </span><span className="menu-icon">▼</span></div>
                                </div>
                                <div className="job-cards">
                                    {isLoading && <Spinner />}
                                    {
                                        courses.map((course) => (
                                            <CoursesCard key={course._id} course={course} />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Lecturers />
                    <Testimonials />
                    <Footer />
                </div>
            </div>

        </>
    )
}

export default HomePage
import React from 'react';
import { useSelector } from 'react-redux';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import './ProgressBar.css';

function ProgressBar() {
    const currentStep = useSelector((state) => state.payment.currentStep);
    const totalSteps = 3; // Adjust based on your actual total steps

    const steps = Array.from({ length: totalSteps }, (_, i) => i + 1);

    return (
        <div className="progress-bar-container">
            {steps.map((step, index) => (
                <React.Fragment key={step}>
                    <div className={`icon-container ${step <= currentStep ? 'completed' : ''}`}>
                        {step < currentStep ? <AiOutlineCheck /> : <AiOutlineClose />}
                    </div>
                    {index < steps.length - 1 && (
                        <div className={`line ${step < currentStep ? 'completed' : ''}`}></div>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
}

export default ProgressBar;

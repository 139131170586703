import React from 'react';

import './App.css';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
// import Cookie from './Components/CookieBanner.js'
import HomePage from './Components/HomePage/HomePage.js';
import CourseId from './Components/CourseId/CourseId.js';
import CourseId2 from './Components/CourseId/CourseId copy.js';
// import Header from './Components/Header/Header.js';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import Footer from './Components/Footer/Footer.js'
import Login from './Pages/Login/Login.js'
// import Login2 from './Pages/Login/demo.js'
import Register from './Pages/Register/Register.js'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Application from './Pages/Application/Application.js';

import Error from './Pages/404/Error.js'
import Verify from './Pages/Verify/Verify.js';
import PasswordResetByMail from './Pages/PasswordResetByMail/PasswordResetByMail.js';
import ResetPassword from './Pages/ResetPassword/ResetPassword.js';
import Categories from './Components/Categories/Categories.js';
import Ingredients from './Components/Categories/Ingredients.js';
import Faq from './Pages/FAQ/Faq.js';
import Appointment from './Pages/Appointment/Appointment.js';
import CookieConsent from 'react-cookie-consent';
import Menu from './Components/Menu/Menu.js'
import Requests from './Components/Payment/Requests.js';

function App() {
  const Layout = () => {
    return (
      <div className="vibex">
        <div>
          {/* <Header /> */}
        </div>
        <div className="vibexMainContainer">
          <div className="vibexHomeComponent">
            <Outlet />
            <Menu />
          </div>
        </div>
        <div className="footer">
          {/* <Footer /> */}
        </div>
        {/* <div className="cookie-section-cover">
          <Cookie />
        </div> */}
      </div>
    )
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "/",
          element:
            <div className="home">
              <HomePage />
            </div>
          ,
        },
        {
          path: "/category/:name",
          element:
            <div className="categoriesData">
              <Categories />
            </div>
          ,
        },
        {
          path: "/ingredients",
          element:
            <div className="categoriesData">
              <Ingredients />
            </div>
          ,
        },
        {
          path: "/course/:id",
          element:
            <div className="detailsId">
              <CourseId />
            </div>
          ,
        },
        {
          path: "/ingridient/:id",
          element:
            <div className="detailsId">
              <CourseId2 />
            </div>
          ,
        },
        {
          path: "/cart",
          element:
            <div className="cart">cart</div>
          ,
        },
      ]
    },
    {
      path: "/login",
      element:
        <div className="login">
          <Login />
        </div>
      ,
    },
    {
      path: "/register",
      element:
        <div className="register">
          <Register />
        </div>
      ,
    },
    {
      path: "/faq",
      element:
        <div className="faq">
          <Faq />
        </div>
      ,
    },
    {
      path: "/account/verify/:token",
      element:
        <div className="register">
          <Verify />
        </div>
      ,
    },
    {
      path: "/account/forgot-password",
      element:
        <div className="register">
          <PasswordResetByMail />
        </div>
      ,
    },
    {
      path: "/account/reset/:token",
      element:
        <div className="register">
          <ResetPassword />
        </div>
      ,
    },
    {
      path: "/app/:id",
      element:
        <div className="register">
          <Application />
        </div>
      ,
    },
    {
      path: "/appointment",
      element:
        <div className="register">
          <Appointment />
        </div>
      ,
    },
    {
      path: "/payment/:id",
      element:
        <div className="register">
          <Requests />
        </div>
      ,
    },
    {
      path: "/*",
      element:
        <div className="err"><Error /> </div>
      ,
    },
  ])

  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer
        position='bottom-center'
        autoClose={5000}
        hideProgressBar={false}
        draggable
        newestOnTop
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme='dark'
      />
      <CookieConsent debug={true} location="bottom" cookieName="abanchichq-cookie-consent" style={{ background: '#f2f2f2', color: 'gray', fontSize: '12px' }} buttonStyle={{ color: 'white', background: 'green', fontSize: '12px' }} buttonText="I understand" expires={3} >
        Privacy is our priority, just like baking the perfect batch of cookies. Here at AbanchiqSchoolOfFormulation, we understand the importance of keeping your personal information safe and secure. Our cookie policy ensures that your privacy is always respected and protected.
        <br />
        <br />
        By clicking " I understand " below, you agree to our <a href="/cookie-policy" style={{ color: 'black', textDecoration: 'underline' }}>Cookie Policy</a>.
      </CookieConsent>
    </>
  );
}

export default App;

import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"
// import { RxAvatar } from "react-icons/rx"
import './register.css'
import { register, reset } from './../../features/Auth/authSlice'
import { toast } from "react-toastify"
import { useDispatch, useSelector } from 'react-redux'
import Spinner from './../../Components/Spinner/Spinner.js'
// import { CiFacebook } from "react-icons/ci";
import { FaUserCircle } from "react-icons/fa";

function Register() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth)

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    file: null,
  });

  const { name, email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onFileChange = (e) =>
    setFormData({ ...formData, file: e.target.files[0] });

  const onSubmit = (e) => {
    e.preventDefault();

    if (!formData.file) {
      toast.error("📸 Oops! Looks like you haven't uploaded a profile picture yet. Click on the icon above to add some personal flair to your account! 🎉");
      return;
    }

    const userData = new FormData();
    userData.append('name', name);
    userData.append('email', email);
    userData.append('password', password);
    if (formData.file) userData.append('profilePicture', formData.file);

    dispatch(register(userData));
  };

  const [visible, setVisible] = useState(false);

  // useEffect(() => {
  //   return () => {
  //     dispatch(reset());
  //   };
  // }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(message)
      dispatch(reset());
    }
    if (isSuccess || user) {
      toast.success("Heads up! Your verification token awaits in your email. Let's get you verified.")
      navigate("/")
      dispatch(reset());
    }
  }, [isSuccess, isError, message, user, navigate, dispatch]);


  if (isLoading) {
    return <Spinner />
  }

  // <label id="userLabel">Upload Profile Picture</label>
  //           <div className="file-input">
  //             <input
  //               className="usInput"
  //               type="file"
  //               id="profilePicture"
  //               name="profilePicture"
  //               onChange={onFileChange}
  //               required
  //             />
  //             Upload Profile Picture
  //           </div>

  return (
    <div className='Login'>
      <form onSubmit={onSubmit}>
        <div className="contanier">
          <h1>Hello,create account</h1>
          <div className="login">
            <label id="userLabel" className='centerPicture'>🔶Upload Photo</label>
            <div className="file-input">
              <input
                type="file"
                id="profilePicture"
                name="profilePicture"
                className="input-file usInput" // Add both classes 'input-file' and 'usInput'
                onChange={onFileChange}
              // required
              />
              <label htmlFor="profilePicture" className="custom-file-upload usInput"><FaUserCircle className='pictureBtnUp' /></label>
            </div>

            <label id="userLabel">🔶Username</label>
            <input
              className="usInput"
              // type="text"
              placeholder="Type your username"
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={onChange}
              required
            />
            <label id="userLabel">🔶Email</label>
            <input
              className="usInput"
              // type="text"
              placeholder="Type your Email"
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={onChange}
              required
            />
            <label id="passLabel">🔶Password</label>
            <input className="pwInput"
              // type="password"
              placeholder="Type your password"
              id="password"
              type={visible ? "text" : "password"}
              name="password"
              autoComplete="current-password"
              value={password}
              onChange={onChange}
              required
            />
            {visible ? (
              <AiOutlineEye
                className="eye"
                onClick={() => setVisible(false)}
              />
            ) : (
              <AiOutlineEyeInvisible
                className="eye"
                onClick={() => setVisible(true)}
              />
            )}
            {/* <p>Or Create Account</p> */}
            {/* <p><Link className='Link'>Forgot Password?</Link></p> */}
          </div>
          <button id="login-Button" type='submit'>Create Account</button>
          <div className="or">
            {/* <hr /> */}
            {/* <p>Or</p> */}
          </div>
          {/* <div className="options"> */}
          {/* <CiFacebook id="F" class="fa-brands fa-facebook" /> */}
          {/* <CiFacebook id="S" class="fa-brands fa-twitter" /> */}
          {/* <Link> <CiFacebook id="F" className="fa-brands fa-google" /></Link> */}
          {/* <i id="S" class="fa-brands fa-twitter"></i> */}
          {/* <i id="T" class="fa-brands fa-google"></i> */}
          {/* </div> */}
          <p className="signUp">Already have an account? <Link className='Link' to='/login'>Login</Link></p>
        </div>
      </form>
    </div>
  )
}

export default Register
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import adminService from "./adminService";



const initialState = {
    Feeds: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}


// @****************************************@
// ---------------GET ALL Courses ----
// @****************************************@

export const getAllAdminFeeds = createAsyncThunk('Feeds/getAllAdminFeeds', async (_, thunkAPI) => {
    try {
        return await adminService.getAllFeeds()
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})
// Login User
// export const login = createAsyncThunk('auth/login', async (user, thunkAPI) => {
//     try {
//         return await authService.login(user)
//     } catch (error) {
//         const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
//         return thunkAPI.rejectWithValue(message)
//     }
// })

// Logout 

// export const logout = createAsyncThunk('auth/logout', async () => {
//     await authService.logout()
// })



export const adminSlice = createSlice({
    name: 'Feeds',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllAdminFeeds.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getAllAdminFeeds.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.Feeds = action.payload
            })
            .addCase(getAllAdminFeeds.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.Feeds = []
            })
    }
})



export const { reset } = adminSlice.actions
export default adminSlice.reducer;
import React, { useEffect } from 'react'
import './../HomePage/HomePage.css'
import { Link, useParams } from 'react-router-dom'
import { TiArrowBack } from "react-icons/ti";
import Logo from './../../Assets/Logo.png'
import { useDispatch, useSelector } from 'react-redux';
import { getCourse, reset } from '../../features/Courses/courseIdSlice';
import Spinner from '../Spinner/Spinner';
import ByCategory from './ByCategory';


function CourseId() {
    const dispatch = useDispatch()
    const { id } = useParams()

    const { course, isLoading } = useSelector((state) => state.Id)


    useEffect(() => {
        if (id) {
            dispatch(getCourse(id))
        }
    }, [dispatch, id])

    useEffect(() => {
        return () => {
            dispatch(reset())
        }
    }, [dispatch])

    const category = course && Array.isArray(course.categories) && course.categories.length > 0 ? course.categories[0] : undefined;

    console.log(category);

    if (isLoading) {
        return <Spinner />
    }

    return (
        <div className='course-id'>
            <Link className="return" to='/'>
                <TiArrowBack />
            </Link>
            <div className="job-overview cover-course-data">
                <div className="job-explain">
                    {course.images && course.images.length > 0 && (
                        <img className="job-bg" alt="" src={course.images[0].url} />
                    )}
                    <div className="job-logos">
                        <img className="" alt="logo" src={Logo} />
                    </div>
                    <div className="job-explain-content">
                        <div className="job-title-wrapper">
                            <div className="job-card-title">{course.title}</div>
                            <div className="job-action">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="heart feather feather-heart">
                                    <path d="M20.8 4.6a5.5 5.5 0 00-7.7 0l-1.1 1-1-1a5.5 5.5 0 00-7.8 7.8l1 1 7.8 7.8 7.8-7.7 1-1.1a5.5 5.5 0 000-7.8z" /></svg>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-share-2"><circle cx="18" cy="5" r="3" /><circle cx="6" cy="12" r="3" /><circle cx="18" cy="19" r="3" /><path d="M8.6 13.5l6.8 4M15.4 6.5l-6.8 4" /></svg>
                            </div>
                        </div>
                        <div className="job-subtitle-wrapper">
                            <div className="company-name">Patreon <span className="comp-location">ASF Course</span></div>
                            <div className="posted">Category :<span className="app-number"> {course.categories}</span></div>
                        </div>
                        <div className="explain-bar">
                            <div className="explain-contents">
                                <div className="explain-title">Cost:Ksh {course.cost}</div>
                                <div className="explain-subtitle">Booking: 0708446730</div>
                            </div>
                            {/* <div className="explain-contents">
                                <div className="explain-title">Work Level</div>
                                <div className="explain-subtitle">Senior level</div>
                            </div>
                            <div className="explain-contents">
                                <div className="explain-title">Employee Type</div>
                                <div className="explain-subtitle">Full Time Jobs</div>
                            </div>
                            <div className="explain-contents">
                                <div className="explain-title">Offer Salary</div>
                                <div className="explain-subtitle">$2150.0 / Month</div>
                            </div> */}
                        </div>
                        <div className="overview-text">
                            <div className="overview-text-header">Overview</div>
                            <div className="overview-text-subheader">{course.overview}</div>
                        </div>
                        <div className="overview-text">
                            <div className="overview-text-header">Course Description</div>
                            <div className="overview-text-item">{course.description}</div>
                        </div>
                    </div>
                </div>

                <div className="job-overview-cards">
                    <ByCategory category={category} />
                </div>

            </div>
        </div>
    )
}

export default CourseId
import React, { useEffect } from 'react'
import './card.css'
import { getNewNewsFeeds } from '../../../features/News/newsSlice'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../Spinner/Spinner'

function NewCoursesCard() {
    const { Feeds, isLoading } = useSelector((state) => state.news)
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(getNewNewsFeeds())
    }, [dispatch])

    function truncateStringByWords(str, maxWords) {
        // Split the string into an array of words
        let words = str.split(/\s+/);

        // If the number of words is greater than maxWords, truncate the array
        if (words.length > maxWords) {
            // Join the first maxWords words back into a string and add an ellipsis
            return words.slice(0, maxWords).join(' ') + '...';
        } else {
            // If the string has maxWords words or fewer, return the original string
            return str;
        }
    }

    return (
        <div className='NewCoursesCardWrap'>
            {isLoading && <Spinner />}
            {
                Feeds.map((Feed) => (
                    <div className="NewCoursesCard" key={Feed._id}>
                        <div className="container">
                            <img src={Feed.images[0].url} alt="" />
                            <div className="card">
                                <span className="text">{Feed.title}</span>
                                <h5>{truncateStringByWords(Feed.description, 10)}</h5>
                                {/* <h5>lorem5</h5> */}
                                <button>See More</button>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default NewCoursesCard
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"
// import { RxAvatar } from "react-icons/rx"
import './login.css'
import { login, reset } from './../../features/Auth/authSlice'
import { toast } from "react-toastify"
import { useDispatch, useSelector } from 'react-redux'
import Spinner from './../../Components/Spinner/Spinner.js'
import { CiHome } from "react-icons/ci";


function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth)

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const { email, password } = formData;

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = (e) => {
        e.preventDefault();
        const userData = {
            email,
            password
        }
        dispatch(login(userData));
    };

    useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, [dispatch]);

    const [visible, setVisible] = useState(false);


    useEffect(() => {
        if (isError) {
            toast.error(message)
            dispatch(reset());
        }

        if (isSuccess || user) {
            navigate('/')
            dispatch(reset());
        }
    }, [isSuccess, isError, message, user, navigate, dispatch]);


    if (isLoading) {
        return <Spinner />
    }

    return (
        <div className='Login'>
            <form onSubmit={onSubmit}>
                <div className="contanier">
                    <h1>Hello,create account</h1>
                    <div className="login">
                        <label id="userLabel">Email</label>
                        <input
                            className="usInput"
                            // type="text"
                            placeholder="Type your Email"
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={onChange}
                            required
                        />
                        <label id="passLabel">Password</label>
                        <input className="pwInput"
                            // type="password"
                            placeholder="Type your password"
                            id="password"
                            type={visible ? "text" : "password"}
                            name="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={onChange}
                            required
                        />
                        {visible ? (
                            <AiOutlineEye
                                className="eye"
                                onClick={() => setVisible(false)}
                            />
                        ) : (
                            <AiOutlineEyeInvisible
                                className="eye"
                                onClick={() => setVisible(true)}
                            />
                        )}
                        {/* <p>Or Create Account</p> */}
                        <p><Link className='Link' to='/account/forgot-password'>Forgot Password?</Link></p>
                    </div>
                    <button id="login-Button" type='submit'>LOGIN</button>
                    <div className="or">
                        {/* <hr /> */}
                        {/* <p>Or</p> */}
                    </div>
                    {/* <div className="options"> */}
                    {/* <CiFacebook id="F" class="fa-brands fa-facebook" /> */}
                    {/* <CiFacebook id="S" class="fa-brands fa-twitter" /> */}
                    {/* <Link to='/'><CiFacebook id="F" class="fa-brands fa-google" /></Link> */}
                    {/* <i id="S" class="fa-brands fa-twitter"></i> */}
                    {/* <i id="T" class="fa-brands fa-google"></i> */}
                    {/* </div> */}
                    <p className="signUp">Need to create an account?  <Link className='Link' to='/register'>Create Account</Link></p>
                    <p className="signUp"><Link className='Link' to='/'><CiHome className='Link dataHomeLink' /></Link></p>
                </div>
            </form>
        </div>
    )
}

export default Login
import React from 'react'
import { Link } from 'react-router-dom'

function CoursesCard({ course }) {
    return (
        <div className='CoursesCard'>
            <Link className="job-card Link">
                <div className="job-card-header">
                    <img src={course.images[0].url} alt="courseImage" />
                    {/* <div className="menu-dot"></div> */}
                </div>
                <div className="job-card-title">{course.title}</div>
                <div className="job-card-subtitle">
                    {course.description}
                </div>
                <div className="job-card-subtitle">
                    contact : 0708446730 To purchase this ingridient
                </div>
                <div className="job-detail-buttons">
                    <button className="search-buttons detail-button">{course.categories}</button>
                    {/* <button className="search-buttons detail-button">Max:{course.duration}</button> */}
                    {/* <button className="search-buttons detail-button">Ksh {course.cost}</button> */}
                    {/* <button className="search-buttons detail-button">Senior Level</button> */}
                </div>
                <div className="job-card-buttons">
                    <button className="search-buttons card-buttons">Ksh {course.cost}</button>
                    {/* <button className="search-buttons card-buttons-msg">Messages</button> */}
                </div>
            </Link>
        </div>
    )
}

export default CoursesCard
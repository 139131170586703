import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProgressBar from './ProgressBar';
import Step1 from './Step1';
import Step2 from './Step2';
import Pay from './Pay';
import { pay, reset, setCurrentStep } from '../../features/Payment/paymentSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Spinner from '../Spinner/Spinner';
import './pay.css'



function Requests() {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        personalPhone: '',
        workPhone: '',
        address: '',
        location: '',
        ID: '',
        paymentType: 5600
    });

    const currentStep = useSelector((state) => state.payment.currentStep);
    const { isLoading, isError, message, isSuccess } = useSelector((state) => state.payment);

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // const handleSubmit = () => {
    //     dispatch(pay(formData));
    //     // Redirect or perform necessary actions after form submission
    //     // history.push('/success'); // You may uncomment this if you have access to history
    // };

    const onSubmit = (e) => {
        e.preventDefault();

        dispatch(pay(formData));
        console.log(formData);
    };

    const handleNext = () => {
        // Validation for Step 1
        if (currentStep === 1) {
            if (!formData.username || !formData.email || !formData.personalPhone || !formData.workPhone) {
                toast.error('Please fill in all fields');
                return; // Stop the function if validation fails
            }
        }

        // Validation for Step 2
        if (currentStep === 2) {
            if (!formData.address || !formData.location || !formData.ID) {
                toast.error('Please fill in all fields');
                return; // Stop the function if validation fails
            }
        }

        // If validation passes, proceed to the next step
        dispatch(setCurrentStep(currentStep + 1));
    };


    const handlePrevious = () => {
        dispatch(setCurrentStep(currentStep - 1));
    };

    useEffect(() => {
        return () => {
            dispatch(reset());
        };
    }, [dispatch]);


    useEffect(() => {
        if (isError) {
            toast.error(message)
            dispatch(reset());
        }

        if (isSuccess) {
            navigate('/')
            // dispatch(reset());
        }
    }, [isSuccess, isError, message, navigate, dispatch]);


    if (isLoading) {
        return <Spinner />
    }

    return (
        <div className="paymentGetway">
            <form onSubmit={onSubmit} className='PayForm'>
                <ProgressBar />
                {/* Render different steps based on the current step */}
                {currentStep === 1 && (
                    <Step1
                        formData={formData}
                        handleChange={handleChange}
                        handleNext={handleNext}
                    />
                )}
                {currentStep === 2 && (
                    <Step2
                        formData={formData}
                        handleChange={handleChange}
                        handleNext={handleNext}
                        handlePrevious={handlePrevious}
                    />
                )}
                {currentStep === 3 && (
                    <Pay
                        formData={formData}
                        handleChange={handleChange}
                        // handleSubmit={handleSubmit}
                        handlePrevious={handlePrevious}
                    />
                )}
            </form>
        </div>
    )
}

export default Requests;

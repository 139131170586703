import React, { useEffect } from 'react'
import './Categories.css'
import Header from '../Header/Header'
import CoursesCard from '../HomePage/CoursesCard/CoursesCard copy'
import Footer from '../Footer/Footer'
import { useDispatch, useSelector } from 'react-redux'
// import { getAllCourses } from '../../features/Courses/courseSlice'
import Spinner from '../Spinner/Spinner'
import { getAllIngridients } from '../../features/Ingridents/ingridientSlice'
import { Link } from 'react-router-dom'
import { TiArrowBack } from "react-icons/ti";

function Ingredients() {
    const { ingridients, isLoading } = useSelector((state) => state.Shop)
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(getAllIngridients())
    }, [dispatch])
    return (
        <div className='Categories'>
            <Link className="return" to='/'>
                <TiArrowBack />
            </Link>
            <div className="job">
                <Header />
                <div className="wrapper">
                    <div className="main-container">
                        <div className="searched-jobs">
                            <div className="searched-bar">
                                <div className="searched-show">Abanchiq Ingredients</div>
                                <div className="searched-sort">Sort by: <span className="post-time">Newest Ingredients </span><span className="menu-icon">▼</span></div>
                            </div>
                            <div className="job-cards">
                                {isLoading && <Spinner />}
                                {ingridients.length === 0 ? (
                                    <p>No ingredients available</p>
                                ) : (
                                    ingridients.map((course) => (
                                        <CoursesCard key={course._id} course={course} />
                                    ))
                                )}
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Ingredients
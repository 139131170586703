import React, { useEffect } from 'react'
import './Categories.css'
import Header from '../Header/Header'
import CoursesCard from '../HomePage/CoursesCard/CoursesCard'
import Footer from '../Footer/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCourses } from '../../features/Courses/courseSlice'
import Spinner from '../Spinner/Spinner'
import { Link } from 'react-router-dom'
import { TiArrowBack } from "react-icons/ti";

function Categories() {
    const { courses, isLoading } = useSelector((state) => state.courses)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getAllCourses({ new: true, category: 'your_category_here' }))
    }, [dispatch])
    return (
        <div className='Categories'>
            <Link className="return" to='/'>
                <TiArrowBack />
            </Link>
            <div className="job">
                <Header />
                <div className="wrapper">
                    <div className="main-container">
                        <div className="searched-jobs">
                            <div className="searched-bar">
                                <div className="searched-show">Abanchiq Courses</div>
                                <div className="searched-sort">Sort by: <span className="post-time">Newest Course </span><span className="menu-icon">▼</span></div>
                            </div>
                            <div className="job-cards">
                                {isLoading && <Spinner />}
                                {courses.length === 0 ? (
                                    <p>No courses available</p>
                                ) : (
                                    courses.map((course) => (
                                        <CoursesCard key={course._id} course={course} />
                                    ))
                                )}
                            </div>
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Categories
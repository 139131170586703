// components/Step1.js

import React from 'react';

const Step1 = ({ formData, handleChange, handleNext }) => {
    return (
        <div>
            <h2>Step 1: Personal Information</h2>
            <label htmlFor="username">Username:</label>
            <input type="text" id="username" name="username" value={formData.username} onChange={handleChange} required />
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
            <label htmlFor="personalPhone">Personal Phone:</label>
            <input type="text" id="personalPhone" name="personalPhone" value={formData.personalPhone} onChange={handleChange} required />
            <label htmlFor="workPhone">Work Phone:</label>
            <input type="text" id="workPhone" name="workPhone" value={formData.workPhone} onChange={handleChange} required />
            <button onClick={handleNext}>Next</button>
        </div>
    );
};

export default Step1;

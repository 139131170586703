import React from 'react'
import Logo from './../../Assets/Logo2.jpeg'
import './footer.css'
import { Link } from 'react-router-dom'
import { MdEmail } from "react-icons/md";
import { BiSolidSend } from "react-icons/bi";
import { PiInstagramLogoFill } from "react-icons/pi";
import { FaTiktok } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io";
import { PiCopyrightFill } from "react-icons/pi";


function Footer() {
    return (
        <div className='Footer'>
            <div className="row">
                <div className="col">
                    <div className="cover">
                        <img src={Logo} alt="logo" />
                        <p>Abanchiq stands as a pioneering institution at the forefront of the beauty and cosmetics industry, offering comprehensive education in the art and science of formulation. With a dedication to innovation, creativity, and sustainability, Abanchiq empowers aspiring cosmetic formulators to delve into the depths of product development while adhering to the highest standards of quality and safety.</p>
                    </div>
                </div>
                <div className="col">
                    <h3>Headquarters <div className="underline">
                        <span></span>
                    </div></h3>
                    <p className='cover2'>The Baazar Mall 6th Floor Wing A</p>
                    <p className='cover2'>Nairobi CBD, 00100</p>
                    <p className='cover2'>Kenya, Nairobi </p>
                    <p className='email-id'> humphreyjoe9@gmail.com </p>
                    <h4 className='cover2'> 0708446730</h4>
                </div>
                <div className="col">
                    <h3>Services <div className="underline">
                        <span></span>
                    </div></h3>
                    <ul>
                        <li>
                            <Link className='Link'>FAQ</Link>
                            <Link className='Link'>Direction</Link>
                            <Link className='Link'>Contact Us</Link>
                            <Link className='Link'>Report</Link>
                            <Link className='Link'>Sell On Abanchiq</Link>
                            <Link className='Link'>Contact 0708446730</Link>
                            {/* <Link className='Link'>Sell On Abanchiq</Link> */}
                        </li>
                    </ul>
                </div>
                <div className="col">
                    <h3>Subscribe To Our Newsletters<div className="underline">
                        <span></span>
                    </div></h3>
                    <form action="submit">
                        <div className="inputWrapper">
                            <MdEmail className='Env' />
                            <input type="email" placeholder="Enter your email" />
                            <button>
                                <BiSolidSend className='send' />
                            </button>
                        </div>
                    </form>
                    <div className="social-icons">
                        <Link className='Link'><PiInstagramLogoFill className='social' /></Link>
                        <Link className='Link'><FaTiktok className='social' /></Link>
                        <Link className='Link'><FaFacebookF className='social' /></Link>
                        <Link className='Link'><FaTwitter className='social' /></Link>
                        <Link className='Link'><IoLogoYoutube className='social' /></Link>
                    </div>
                </div>
            </div>
            <hr />
            <p className='copyright'>Powered by <Link className='Link' to='https://encryptfirm.vercel.app'>E|ncrypt</Link> <PiCopyrightFill /> All Rights Reserved</p>
        </div>
    )
}

export default Footer
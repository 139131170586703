import React from 'react'
import './Test.css'
function Testimonials() {
    return (
        <div className='Testimonials'>
            <h3>Testimonials</h3>
            <section className="container container--testimonials">
                <div className="testimonial">
                    <p className="testimonial__text mb-25">

                        Certainly! Here are three testimonials that showcase the diverse benefits students have experienced at Abanchiq School of Formulation:

                        Testimonial 1: Laura's Journey to Natural Beauty Entrepreneurship

                        Before enrolling in Abanchiq School of Formulation, I was merely a beauty enthusiast with a dream to create my own line of natural skincare products. The comprehensive curriculum, which covered everything from the basics of cosmetic chemistry to advanced formulation techniques, transformed my dream into a tangible reality. The faculty's dedication to sustainability and natural ingredients resonated with my values, empowering me to develop products that were not only effective but also environmentally friendly. Thanks to the hands-on experience and the final project that simulated real-world product development, I launched my brand six months after graduation. Abanchiq School didn't just educate me; it gave me the confidence to become an entrepreneur in the beauty industry.
                    </p>

                    <div className="testimonial__details">
                        <div className="testimonial__img">
                            <img src="https://images.unsplash.com/photo-1639761325466-f19ba403c970?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NTd8fGJsYWNrbWFufGVufDB8fDB8fHww" alt="Timmy Dicki" />
                        </div>
                        <div className="testimonial__info">
                            <h4 className="testimonial__name">Timmy Kioko</h4>
                            <h5 className="testimonial__job">Entreprenuer Beauty Products</h5>
                        </div>
                    </div>
                </div>

                <div className="testimonial">
                    <p className="testimonial__text mb-25">
                        As a chemist looking to pivot into the cosmetic industry, Abanchiq School of Formulation offered me the perfect blend of science and creativity. The modules on advanced formulation and sensory evaluation were game-changers, allowing me to understand the intricacies of creating products that feel as good as they work. The school's state-of-the-art labs and access to cutting-edge ingredients enabled me to experiment and innovate beyond traditional beauty norms. Networking with industry professionals and alumni opened doors to opportunities I hadn't imagined, leading to my current role as a lead formulator at a renowned beauty brand. Abanchiq didn't just broaden my skill set; it redefined my career trajectory.
                    </p>

                    <div className="testimonial__details">
                        <div className="testimonial__img">
                            <img src="https://images.unsplash.com/photo-1665947675618-39ec919ac4b5?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NTN8fGJsYWNrbWFufGVufDB8fDB8fHww" alt="Valerie King" />
                        </div>
                        <div className="testimonial__info">
                            <h4 className="testimonial__name">
                                Martin Ogutu</h4>
                            <h5 className="testimonial__job">Skin Specialist</h5>
                        </div>
                    </div>
                </div>
                <div className="testimonial">
                    <p className="testimonial__text mb-25">
                        As someone passionate about holistic wellness and beauty, finding a program that addressed both was a challenge until I discovered Abanchiq School of Formulation. The holistic approach to beauty product formulation, focusing on the interconnection between skin health, natural ingredients, and ethical sourcing, was enlightening. The mentorship from instructors who are experts in both traditional and modern beauty practices enriched my learning experience, making it deeply personal and highly practical. The collaborative projects, particularly the community initiative to create sustainable beauty solutions for underserved areas, were profoundly impactful. Abanchiq has not only equipped me with the knowledge to formulate holistic beauty products but also instilled a sense of purpose in my career.
                    </p>

                    <div className="testimonial__details">
                        <div className="testimonial__img">
                            <img src="https://images.unsplash.com/photo-1479936343636-73cdc5aae0c3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80" alt="Terrell O'Keefe" />
                        </div>
                        <div className="testimonial__info">
                            <h4 className="testimonial__name">Soila Siameto</h4>
                            <h5 className="testimonial__job">Facial skin Formulator</h5>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Testimonials
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import courseService from "./courseService";



const initialState = {
    course: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}



// @****************************************@
// ---------------GET Single Course ----
// @****************************************@

export const getCourse = createAsyncThunk('course/getCourse', async (id, thunkAPI) => {
    try {
        return await courseService.getCourse(id)
    } catch (
    error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)

    }
})



export const courseSlice = createSlice({
    name: 'course',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCourse.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getCourse.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.course = action.payload
            })
            .addCase(getCourse.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.course = []
            })

    }
})



export const { reset } = courseSlice.actions
export default courseSlice.reducer;
import axios from 'axios'

const API_URL = 'https://abanchiqschoolapi.onrender.com/api/auth/adminstration/sales/'



const getAllFeeds = async () => {
    const response = await axios.get(`${API_URL}`)
    return response.data
}

const getNewNews = async () => {
    try {
        const response = await axios.get(`${API_URL}?new=true`);
        // console.log(response);
        return response.data;

    } catch (error) {
        console.error('Error fetching new courses:', error);
        throw error;
    }

};

const getNewsByCategory = async (category) => {
    try {
        const response = await axios.get(`${API_URL}?category=${category}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching courses by category:', error);
        throw error;
    }
};


const newsService = {
    getAllFeeds,
    getNewNews,
    getNewsByCategory
}


export default newsService











































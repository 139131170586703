// src/features/appointmentSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from './appointmentService';

// Define the initial state
const initialState = {
    appointment: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    messageData: '',
};

// Create the asyncThunk
export const createAppointment = createAsyncThunk(
    'appointments/create',
    async (appointmentData, thunkAPI) => {
        try {
            return await authService.appointment(appointmentData);
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const appointmentSlice = createSlice({
    name: 'appointment',
    initialState,
    reducers: {
        reset: (state) => {
            state.appointment = null;
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = false;
            state.messageData = '';
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createAppointment.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createAppointment.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.appointment = action.payload;
            })
            .addCase(createAppointment.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.messageData = action.payload;
                state.appointment = null;
            });
    },
});

export const { reset } = appointmentSlice.actions;

export default appointmentSlice.reducer;

import React from 'react'
import './Menu.css'
import { CiHome } from "react-icons/ci";
import { Link } from 'react-router-dom';
import { RiUploadCloud2Fill } from "react-icons/ri";
import { SiGooglephotos } from "react-icons/si";
import { FaUser } from "react-icons/fa";
// import { RiNotification4Fill } from "react-icons/ri";
import { BiSolidMessageSquareDetail } from "react-icons/bi";
import { useSelector } from 'react-redux';

import { IoBookSharp } from "react-icons/io5";
import { RiPlantFill } from "react-icons/ri";
import { HiHome } from "react-icons/hi2";
import { BsBoxFill } from "react-icons/bs";
import { GiChemicalTank } from "react-icons/gi";

const Menu = () => {

    // const { user } = useSelector(state => state.auth)
    return (
        <>
            <div className="navigation">
                <Link to='/' className='Link'>
                    <HiHome className='icons' />
                    {/* <p className='icon-names'>Home</p> */}
                </Link>
                {/* <Link to={`/course/${user?._id}`} className='Link'> */}
                <Link to={`/category/courses`} className='Link'>
                    <IoBookSharp className='icons' />
                    {/* <p className='icon-names'>Courses</p> */}
                </Link>
                <Link to={`/ingredients`} className='Link'>
                    <RiPlantFill className='icons' />
                    {/* <p className='icon-names'>Ingridients</p> */}
                </Link>
                <Link to={`/packaging`} className='Link'>
                    <BsBoxFill className='icons' />
                    {/* <p className='icon-names'>Packaging</p> */}
                </Link>
                <Link to={`/manufacturing`} className='Link'>
                    <GiChemicalTank className='icons' />
                    {/* <p className='icon-names'>Manufacturing</p> */}
                </Link>
            </div>
        </>
    )
}

export default Menu

import React from 'react'
import Like from './../../Assets/like.png'
import Share from './../../Assets/share.png'
import Bookmark from './../../Assets/bookmark.png'
import Slider from "react-slick";


const settings = {
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false
};

function PostCard({ feed }) {
    return (
        <div className="news">
            <div className="top">
                <div className="imageP">
                    <img src="https://plus.unsplash.com/premium_photo-1688350808212-4e6908a03925?w=600&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mjl8fHVzZXJ8ZW58MHx8MHx8fDA%3D" alt="pdata" />
                </div>
                <div className="centerData">
                    <h3>{feed.title}</h3>
                    <h4>{feed.description}</h4>
                </div>
                <div className="options">
                    <div className="cut">:</div>
                    <div className="cut">X</div>
                </div>
            </div>
            <div className="post">
                {/* <Slider {...settings}> */}
                {/* {feed.images.map((image) => ( */}
                <div>
                    <img src={feed.images[0].url} alt='Feeds' />
                </div>
                {/* ))} */}
                {/* </Slider> */}
            </div>
            <div className="optionsData">
                <div className="icons">
                    <img src={Like} alt="Share" />
                </div>
                <div className="icons">
                    <img src={Bookmark} alt="Bookmark" />
                </div>
                <div className="icons">
                    <img src={Share} alt="Share" />
                </div>
            </div>
        </div>
    )
}

export default PostCard
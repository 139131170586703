import axios from 'axios';

const API_URL = 'https://abanchiqschoolapi.onrender.com/api/auth/adminstration/appointments';

// Register user
const appointment = async (appointmentData) => {
    const response = await axios.post(API_URL, appointmentData);

    return response.data;
};



const authService = {
    appointment,
};

export default authService;

import React from 'react'
import Mpesa from './../../Assets/mpesa-seeklogo.png'
import './pay.css'
import { IoArrowBackCircleSharp } from "react-icons/io5";


function Pay({ formData, handleChange, handlePrevious }) {
    // Provide a default value for paymentType if not defined
    const paymentType = formData.paymentType;

    return (
        <div className='Pay'>
            <div className="circles">
                <div className="circle1"></div>
                <div className="circle2"></div>
            </div>
            <div className="containerPay">
                <div className="image">
                    <img src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8dXNlcnxlbnwwfHwwfHx8MA%3D%3D" alt="user" />
                </div>
                <div className="hello">
                    <p>Hello</p>
                    <span>👋🏽</span>
                    <p>Soila</p>
                </div>
                <label htmlFor="paymentType">Payment Type:</label>
                {/* Use the provided default value */}
                <input type="text" id="paymentType" name="paymentType" value={paymentType} onChange={handleChange} />
                <p className="front">You are purchasing the following products:</p>
                <div className="portfolio">
                    <p>My Basket:</p>
                    <div className="project1">
                        Project1
                    </div>
                    <div className="project1">
                        Project2
                    </div>
                    <div className="project1">
                        Project3
                    </div>
                    <div className="project1">
                        Project4
                    </div>
                </div>
                <div className="PayBtn">
                    <button onClick={handlePrevious} className='Btn-Back'><IoArrowBackCircleSharp /><h2>Previous</h2></button>
                    <button type='submit'>
                        <h2>Pay Now</h2>
                        <img src={Mpesa} alt="" />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Pay

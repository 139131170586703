import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCategoryCourses } from '../../features/Courses/courseSlice'
import Spinner from '../Spinner/Spinner'
// import { Link } from 'react-router-dom'
import DataCourse from './DataCourse/DataCourse'

function ByCategory({ category }) {

    const dispatch = useDispatch()
    const { courses, isLoading } = useSelector((state) => state.courses)

    useEffect(() => {
        dispatch(getAllCategoryCourses(category))
    }, [dispatch, category])

    return (
        <div className="job-overview-card">
            <h2>Check related courses</h2>
            {isLoading && <Spinner />}
            {
                courses.map((course) => (
                    <DataCourse key={course._id} course={course} />
                ))
            }
        </div>
    )
}

export default ByCategory
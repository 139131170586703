import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import newsService from "./newsService";



const initialState = {
    Feeds: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}


// @****************************************@
// ---------------GET ALL Courses ----
// @****************************************@

export const getAllNewsFeeds = createAsyncThunk('Feeds/getAllNewsFeeds', async (_, thunkAPI) => {
    try {
        return await newsService.getAllFeeds()
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})
// @****************************************@
// ---------------GET New Feeds ----
// @****************************************@

export const getNewNewsFeeds = createAsyncThunk('Feeds/getNewNewsFeeds', async (_, thunkAPI) => {
    try {
        return await newsService.getNewNews()
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})
// @****************************************@
// ---------------GET News Category ----
// @****************************************@

export const getCategoryNewsFeeds = createAsyncThunk('Feeds/getCategoryNewsFeeds', async (_, thunkAPI) => {
    try {
        return await newsService.getNewsByCategory()
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


export const newsSlice = createSlice({
    name: 'Feeds',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllNewsFeeds.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getAllNewsFeeds.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.Feeds = action.payload
            })
            .addCase(getAllNewsFeeds.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.Feeds = []
            })
            .addCase(getNewNewsFeeds.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getNewNewsFeeds.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.Feeds = action.payload
            })
            .addCase(getNewNewsFeeds.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.Feeds = []
            })
            .addCase(getCategoryNewsFeeds.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getCategoryNewsFeeds.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.Feeds = action.payload
            })
            .addCase(getCategoryNewsFeeds.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.Feeds = []
            })

    }
})



export const { reset } = newsSlice.actions
export default newsSlice.reducer;
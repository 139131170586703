import axios from 'axios'

const API_URL = 'https://abanchiqschoolapi.onrender.com/api/auth/adminstration/shop/'


const getAllIngridients = async () => {
    const response = await axios.get(`${API_URL}`)
    return response.data
}
const getIngridient = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/find/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching Ingridients:', error);
        throw error;
    }
};


const ingridientService = {
    getAllIngridients,
    getIngridient
}


export default ingridientService











































import axios from 'axios'

const API_URL = 'https://abanchiqschoolapi.onrender.com/api/courses'


const getAllCourses = async () => {
    const response = await axios.get(`${API_URL}`)
    return response.data
}
const getCourse = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/find/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching course:', error);
        throw error;
    }
};

const getNewCourses = async () => {
    try {
        const response = await axios.get(`${API_URL}?new=true`);
        // console.log(response);
        return response.data;

    } catch (error) {
        console.error('Error fetching new courses:', error);
        throw error;
    }
    // console.log();
};

const getCoursesByCategory = async (category) => {
    try {
        const response = await axios.get(`${API_URL}?category=${category}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching courses by category:', error);
        throw error;
    }
};


const courseService = {
    getAllCourses,
    getCourse,
    getNewCourses,
    getCoursesByCategory
}


export default courseService











































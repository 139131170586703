// components/Step2.js

import React from 'react';

const Step2 = ({ formData, handleChange, handleNext, handlePrevious }) => {
    return (
        <div>
            <h2>Step 2: Address Information</h2>
            <label htmlFor="address">Address:</label>
            <input type="text" id="address" name="address" value={formData.address} onChange={handleChange} />
            <label htmlFor="location">Location:</label>
            <input type="text" id="location" name="location" value={formData.location} onChange={handleChange} />
            <label htmlFor="ID">ID:</label>
            <input type="text" id="ID" name="ID" value={formData.ID} onChange={handleChange} />
            <button onClick={handlePrevious}>Previous</button>
            <button onClick={handleNext}>Next</button>
        </div>
    );
};

export default Step2;

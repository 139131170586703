import React, { useEffect } from 'react'
// import Banner from './Banner'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../Spinner/Spinner'
import { getAllNewsFeeds, getNewNewsFeeds } from '../../features/News/newsSlice'
import Slider from "react-slick";
import './Banner.css'
import { AiFillInstagram } from "react-icons/ai";
import { GrTwitter } from "react-icons/gr";
import { FaTiktok } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { Link } from "react-router-dom";

function News() {
    const dispatch = useDispatch()
    const { Feeds, isLoading } = useSelector((state) => state.news)

    useEffect(() => {
        dispatch(getNewNewsFeeds())
    }, [dispatch])

    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: true
    };

    function truncateStringByWords(str, maxWords) {
        // Split the string into an array of words
        let words = str.split(/\s+/);

        // If the number of words is greater than maxWords, truncate the array
        if (words.length > maxWords) {
            // Join the first maxWords words back into a string and add an ellipsis
            return words.slice(0, maxWords).join(' ') + '...';
        } else {
            // If the string has maxWords words or fewer, return the original string
            return str;
        }
    }
    return (
        <div>
            <div className="Banner-sec">
                {/* <h2></h2> */}
                <Slider {...settings}>
                    {isLoading && <Spinner />}
                    {
                        Feeds.map((feed) => (
                            <div className="cover-hero" key={feed._id}>
                                <div className="hero">
                                    <div className="hero__content">
                                        <h1>{feed.title}</h1>
                                        <h3>{feed.categories}</h3>
                                        <hr />
                                        <p>{truncateStringByWords(feed.description, 20)}</p>
                                        {/* <h5></h5> */}
                                        {/* <button className="btn btn--contact"><Link className='Link' to='/'>Sign Up</Link></button> */}
                                        {/* <button className="btn btn--resume"><Link className='Link' to='/'>EXPLORE</Link></button> */}

                                        <div className="social">
                                            <Link><AiFillInstagram className='bx' /></Link>
                                            <Link> <FaFacebookF className='bx' /></Link>
                                            <Link><FaTiktok className='bx' /></Link>
                                            <Link><GrTwitter className='bx' /></Link>
                                        </div>
                                    </div>
                                    <div className="hero__img">
                                        <img src={feed.images[0].url} alt="hero" />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Slider>
            </div>

        </div>
    )
}

export default News
import React, { useEffect } from 'react'
import { getAllAdminFeeds } from '../../features/Admin/adminSlice.js'
import { useDispatch, useSelector } from 'react-redux'
import Highlights from './Highlights.js'
import Spinner from '../Spinner/Spinner.js'

function AdminFeeds() {
    const dispatch = useDispatch()
    const { Feeds, isLoading } = useSelector((state) => state.admin)

    useEffect(() => {
        dispatch(getAllAdminFeeds())
    }, [dispatch])

    return (
        <div className="wrapper2">
            {isLoading && <Spinner />}
            {
                Feeds.map((feed) => (
                    <Highlights key={feed._id} feed={feed} />
                ))
            }
        </div>
    )
}

export default AdminFeeds